import React from 'react';

import Layout from '../components/layout';
import { Card, Container, Divider, Icon } from 'semantic-ui-react';

import './index.css';

class IndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <Container>
          <p>
            I currently work at Stripe, on our developer experience team working
            on building better tooling and developer products for our users.
          </p>
          <p>
            Previously, I worked at Yelp building the{' '}
            <a href="https://yelp.com/developers/fusion">public API</a>, working
            on internal and mobile API infrastracture, building a{' '}
            <a href="https://www.yelp.com/developers/graphql/guides/intro">
              GraphQL
            </a>{' '}
            API, and on service infrastructure.
          </p>
        </Container>
        <Divider horizontal>Projects</Divider>
        <Container>
          <Card.Group centered itemsPerRow={2}>
            <Card
              image="/stripecli.png"
              header="Stripe CLI"
              meta="Stripe"
              extra={
                <Container>
                  <p>
                    <a href="https://stripe.com/blog/stripe-cli">
                      <Icon name="linkify" />
                      Read more
                    </a>
                  </p>
                  <p>
                    <a href="https://news.ycombinator.com/item?id=21454153">
                      <Icon name="hacker news" />
                      Discussion
                    </a>
                  </p>
                </Container>
              }
            />
            <Card
              image="/fusionapi.png"
              header="Yelp Fusion API"
              meta="Yelp"
              extra={
                <a href="https://yelp.com/fusion">
                  <Icon name="linkify" />
                  Read more
                </a>
              }
            />
            <Card
              image="/yelpgraphql.png"
              header="Yelp GraphQL"
              meta="Yelp"
              extra={
                <Container>
                  <p>
                    <a href="https://engineeringblog.yelp.com/2017/05/introducing-yelps-local-graph.html">
                      <Icon name="linkify" />
                      Read more
                    </a>
                  </p>
                  <p>
                    <a href="https://news.ycombinator.com/item?id=14260764">
                      <Icon name="hacker news" />
                      Discussion
                    </a>
                  </p>
                </Container>
              }
            />
            <Card
              image="/yelpdataset.png"
              header="Yelp Dataset"
              meta="Yelp"
              extra={
                <a href="https://yelp.com/dataset">
                  <Icon name="linkify" />
                  Read more
                </a>
              }
            />
          </Card.Group>
        </Container>
      </Layout>
    );
  }
}

export default IndexPage;
